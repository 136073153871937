import { useEffect, useState, useDeferredValue } from "react";
import useSWR from "swr";
import { Pagination, Flex } from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";
import useFilters from "../../../../common/hooks/useFilters/useFilters";
import DataTable from "../../../../common/components/DataTable/DataTable";

const DataWrapper = ({
  endpoint,
  config,
  filterOptions,
  sortOptions,
  onSelectHandler,
}) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterQuery, setFilterQuery] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [orderingValue, setOrderingValue] = useState("");

  const deferredSearchQuery = useDeferredValue(searchInputValue);

  const { form, onSubmit } = useFilters({
    onFilterChange: (query) => {
      setFilterQuery(query);
    },
  });

  const buildUrl = () => {
    let url = endpoint;
    const params = [];

    if (filterQuery) {
      params.push(filterQuery.slice(1));
    }

    if (deferredSearchQuery) {
      params.push(`search=${encodeURIComponent(deferredSearchQuery)}`);
    }

    if (orderingValue) {
      params.push(`ordering=${encodeURIComponent(orderingValue)}`);
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    if (!url.includes("page=")) {
      url += (url.includes("?") ? "&" : "?") + `page=${pageIndex}`;
    }

    return url;
  };

  const fullUrl = buildUrl();

  const { data, error: pageError, isLoading } = useSWR(fullUrl, fetcher);

  useEffect(() => {
    if (data && data.count) {
      const totalItems = data.count;
      const itemsPerPage = 10;
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    }
  }, [data, setTotalPages]);

  useEffect(() => {
    setPageIndex(1);
  }, [deferredSearchQuery, filterQuery, orderingValue]);

  const handlePageChange = (page) => {
    setPageIndex(page);
  };

  const handleSearch = (event) => {
    setSearchInputValue(event.target.value);
  };

  const handleOrdering = (value) => {
    setOrderingValue(value);
  };

  const handleSelectionChange = (newSelection) => {
    if (onSelectHandler) {
      onSelectHandler(newSelection);
    }
  };

  if (pageError) {
    return <p>Something went wrong</p>;
  }

  return (
    <>
      <DataTable
        data={isLoading ? [] : data.results}
        config={config}
        loading={isLoading}
        form={form}
        onSubmit={onSubmit}
        filterOptions={filterOptions}
        sortOptions={sortOptions}
        onSearch={handleSearch}
        searchValue={searchInputValue}
        onOrdering={handleOrdering}
        orderingValue={orderingValue}
        onSelectionChange={handleSelectionChange}
      />

      <Flex justify="end" mt="lg">
        <Pagination
          page={pageIndex}
          onChange={handlePageChange}
          total={totalPages}
          defaultValue={pageIndex}
          siblings={1}
        />
      </Flex>
    </>
  );
};

export default DataWrapper;
