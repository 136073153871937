import { Checkbox, Radio, Switch } from "@mantine/core";
import { Controller } from "react-hook-form";

const FilterCheckboxGroup = ({
  options,
  type,
  form,
  name,
  onChange,
  ...props
}) => {
  const renderField = (field) => {
    switch (type) {
      case "checkbox":
        return (
          <Checkbox.Group
            {...field}
            {...props}
            onChange={(value) => {
              field.onChange(value);
              onChange();
            }}
            my={"sm"}
          >
            {options.map((option) => (
              <Checkbox
                key={option.value}
                value={option.value}
                label={option.label}
                radius="sm"
                style={{ marginTop: "0.4em" }}
              />
            ))}
          </Checkbox.Group>
        );

      case "radio":
        return (
          <Radio.Group
            {...field}
            {...props}
            onChange={(value) => {
              field.onChange(value);
              if (onChange) onChange(value);
            }}
          >
            {options.map((option) => (
              <Radio
                key={option.value}
                value={option.value}
                label={option.label}
                style={{ marginTop: "6px" }}
              />
            ))}
          </Radio.Group>
        );

      case "switch":
        return (
          <Switch
            {...field}
            checked={field.value}
            onChange={(event) => {
              const value = event.currentTarget.checked;
              field.onChange(value);
              if (onChange) onChange(value);
            }}
            {...props}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field }) => renderField(field)}
    />
  );
};

export default FilterCheckboxGroup;
