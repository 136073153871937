export const superuserMenu = [
  { label: "Dashboard", icon: undefined, link: "/dashboard", roles: [] },
  {
    label: "Covers",
    icon: undefined,
    initiallyOpened: false,
    links: [
      { label: "Adventures", link: "/covers/adventures/" },
      { label: "Benefits", link: "/covers/benefits" },
      { label: "Mother Covers", link: "/covers/mother-covers/" },
      { label: "Master Covers", link: "/covers/master-covers/" },
      { label: "Customize Covers", link: "/covers/customize-covers/" },
    ],
    roles: ["is_superuser"],
  },
  {
    label: "Master Wallet",
    icon: undefined,
    link: "/master-wallet",
    roles: ["is_superuser"],
  },
  { label: "Users", icon: undefined, link: "/users/", roles: ["is_superuser"] },
  {
    label: "Vendors",
    icon: undefined,
    link: "/vendor/",
    roles: ["is_superuser"],
  },
  {
    label: "Operators",
    icon: undefined,
    link: "/operators/",
    roles: ["is_superuser", "Operator"],
  },
  {
    label: "Payments",
    icon: undefined,
    link: "/payments/",
    roles: ["is_superuser"],
  },
  {
    label: "Issuance",
    icon: undefined,
    initiallyOpened: false,
    links: [
      { label: "Bulk Issuance", link: "/issuance/bulk/" },
      { label: "Single Issuance", link: "/issuance/single/" },
      { label: "Policies", link: "/issuance/policies/" },
      { label: "Quote Links", link: "/issuance/quote-links/" },
    ],
    roles: ["is_superuser", "Issuance Team", "Operator"],
  },
];

export const staffMenu = [
  { label: "Dashboard", icon: undefined, link: "/dashboard", roles: [] },
  {
    label: "Assign Plans",
    icon: undefined,
    link: "/covers/",
    roles: [],
  },
  {
    label: "Wallet",
    icon: undefined,
    link: "/wallet/",
    roles: [],
  },
  {
    label: "Issuance",
    icon: undefined,
    initiallyOpened: false,
    links: [
      { label: "Bulk Issuance", link: "/issuance/bulk/" },
      { label: "Single Issuance", link: "/issuance/single/" },
      { label: "Policies", link: "/issuance/policies/" },
      { label: "Quote Links", link: "/issuance/quote-links/" },
    ],
    roles: [],
  },
];

export const operatorMenu = [
  { label: "Dashboard", icon: undefined, link: "/dashboard", roles: [] },
  {
    label: "Wallet",
    icon: undefined,
    link: "/wallet/",
    roles: [],
  },
  {
    label: "Assign Covers",
    icon: undefined,
    link: "/covers/",
    roles: [],
  },
  {
    label: "Commission",
    icon: undefined,
    link: "/commission/",
    roles: [],
  },
  {
    label: "Payments",
    icon: undefined,
    link: "/payments/",
    roles: [],
  },
  {
    label: "Issuance",
    icon: undefined,
    initiallyOpened: false,
    links: [
      { label: "Bulk Issuance", link: "/issuance/bulk/" },
      { label: "Single Issuance", link: "/issuance/single/" },
      { label: "Policies", link: "/issuance/policies/" },
      { label: "Quote Links", link: "/issuance/quote-links/" },
    ],
    roles: [],
  },
];
