import { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Text,
  Paper,
  Grid,
  Skeleton,
  Stack,
  UnstyledButton,
  Group,
  rem,
} from "@mantine/core";
import { SegmentedControl } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import useStatsFilters from "../../hooks/useStatsFilters";
import classes from "./CountStatsControls.module.css";

const CountStatsControls = ({
  data,
  isLoading,
  onFilterChange,
  statsOptions,
  filterOptions,
  statsTitle,
  changeDayWise,
}) => {
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [date, setDate] = useState(dayjs().toDate());

  const { form, onSubmit } = useStatsFilters({
    onFilterChange: onFilterChange,
  });

  useEffect(() => {
    const subscription = form.watch((value) => {
      onSubmit(value);
    });
    return () => subscription.unsubscribe();
  }, [form, onSubmit]);

  const handleDayWise = (newDate) => {
    setDate(newDate);
    const startDate = dayjs(newDate).format("YYYY-MM-DD");
    const endDate = dayjs(newDate).format("YYYY-MM-DD");
    onFilterChange(
      `?filter=custom&start_date=${startDate}&end_date=${endDate}`
    );
  };

  const skeletonCards = statsOptions.map(({ label }, index) => (
    <Grid.Col span={4} key={index}>
      <Paper className={classes.stat} radius="md" shadow="md" p="xs">
        <Text mb="sm" className={classes.label}>
          {label}
        </Text>
        <Skeleton height={22} radius="sm" />
      </Paper>
    </Grid.Col>
  ));

  const stats = statsOptions.map(({ label, key }) => (
    <Grid.Col span={3} key={key}>
      <Paper className={classes.stat} radius="md" shadow="md" p="xs">
        <Text mb="sm" className={classes.label}>
          {label}
        </Text>
        <Text fz="xs" className={classes.count}>
          <span className={classes.value}>{data?.[key] || 0}</span>
        </Text>
      </Paper>
    </Grid.Col>
  ));

  return (
    <div className={classes.root}>
      <Stack spacing="md">
        <Group justify="space-between" gap="sm" grow mb={"sm"}>
          <Text className={classes.statsTitle}>{statsTitle}</Text>

          <SegmentedControl
            value={form.watch("filter")}
            onChange={(value) => {
              form.setValue("filter", value);
              setShowCustomDatePicker(value === "custom");
            }}
            data={filterOptions.map(({ label, value }) => ({
              label,
              value,
            }))}
          />
        </Group>

        {showCustomDatePicker && (
          <Grid>
            <Grid.Col span={6}>
              <DatePickerInput
                {...form.register("start_date")}
                label="Start Date"
                placeholder="Select start date"
                onChange={(date) =>
                  form.setValue("start_date", dayjs(date).format("YYYY-MM-DD"))
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DatePickerInput
                {...form.register("end_date")}
                label="End Date"
                placeholder="Select end date"
                onChange={(date) =>
                  form.setValue("end_date", dayjs(date).format("YYYY-MM-DD"))
                }
              />
            </Grid.Col>
          </Grid>
        )}

        <Grid>{isLoading ? skeletonCards : stats}</Grid>
      </Stack>

      {changeDayWise && (
        <div className={classes.controls}>
          <UnstyledButton
            variant="filled"
            className={classes.control}
            onClick={() => handleDayWise(dayjs(date).add(1, "day").toDate())}
            disabled={dayjs(date).isSame(dayjs(), "day")}
          >
            <img
              style={{ width: rem(16), height: rem(16) }}
              className={classes.controlIcon}
              stroke={1.5}
              src="/images/svg/chevronUp.svg"
            />
          </UnstyledButton>

          <UnstyledButton
            className={classes.control}
            onClick={() =>
              handleDayWise(dayjs(date).subtract(1, "day").toDate())
            }
          >
            <img
              style={{ width: rem(16), height: rem(16) }}
              className={classes.controlIcon}
              stroke={1.5}
              src="/images/svg/chevronDown.svg"
            />
          </UnstyledButton>
        </div>
      )}
    </div>
  );
};

export default CountStatsControls;
