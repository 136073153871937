import { useForm } from "react-hook-form";

const useFilters = ({ onFilterChange }) => {
  const form = useForm();

  const onSubmit = (formData) => {
    const queryParams = Object.entries(formData).reduce((acc, [key, value]) => {
      if (value) {
        if (Array.isArray(value) && value.length > 0) {
          value.forEach((val) => {
            acc.push(`${key}=${encodeURIComponent(val)}`);
          });
        } else if (key === "ordering") {
          acc.push(`ordering=${encodeURIComponent(value)}`);
        }
      }
      return acc;
    }, []);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    onFilterChange(queryString);
  };

  return {
    form,
    onSubmit,
  };
};

export default useFilters;
