import { useState } from "react";
import { useFieldArray, Controller, useWatch } from "react-hook-form";
import {
  Button,
  Table,
  NumberInput,
  TextInput,
  Select,
  Paper,
  Group,
  Checkbox,
  ActionIcon,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { planTypes } from "../../../../../data/covers";
import ConfirmModal from "../../../../../common/components/ConfirmModal/ConfirmModal";

function BenefitsTable({
  plan,
  benefits,
  control,
  reset,
  onSubmit,
  mode,
  isSubmitting,
  handleOnDelete,
}) {
  const {
    fields: rowFields,
    append: appendRow,
    remove: removeRow,
    update: updateRow,
  } = useFieldArray({
    control,
    name: "rows",
  });

  const {
    fields: columnFields,
    append: appendColumn,
    remove: removeColumn,
  } = useFieldArray({
    control,
    name: "columns",
  });

  const isEditMode = mode === "edit";

  const watchedRows = useWatch({ control, name: "rows" });

  const [opened, { open, close }] = useDisclosure(false);

  const [rowIndexToDelete, setRowIndexToDelete] = useState(null);

  const getAvailableOptions = (index) => {
    if (!benefits || benefits.length === 0) {
      return [];
    }

    const selectedBenefits = watchedRows
      .map((row, i) => (i === index ? null : row.benefit))
      .filter(Boolean);

    return benefits.filter(
      (benefit) => !selectedBenefits.includes(benefit.value)
    );
  };

  const handleRemoveColumn = (columnIndex) => {
    removeColumn(columnIndex);
    watchedRows.forEach((row, rowIndex) => {
      const updatedCoverages = [...row.coverages];
      updatedCoverages.splice(columnIndex, 1);
      updateRow(rowIndex, { ...row, coverages: updatedCoverages });
    });
  };

  const handleRemoveRowClick = (row) => {
    if (!row) {
      return;
    }

    setRowIndexToDelete(row.benefit_id);
    open();
  };

  const handleConfirmRemoveRow = () => {
    if (rowIndexToDelete !== null) {
      removeRow(rowIndexToDelete);
      handleOnDelete(rowIndexToDelete);
      close();
    }
  };

  return (
    <>
      <Paper py="lg" style={{ overflowX: "auto" }}>
        <form onSubmit={onSubmit}>
          <Table
            withRowBorders={false}
            verticalSpacing="md"
            style={{ minWidth: "1200px" }}
          >
            <Table.Thead>
              <Table.Tr
                style={{
                  borderRadius: "8px 0px 0px 0px",
                  background: "rgba(51, 51, 51, 0.18)",
                }}
              >
                {plan !== planTypes.MOTHER && (
                  <Table.Th style={{ width: "5%" }}>Select</Table.Th>
                )}
                <Table.Th style={{ width: "20%" }}>Benefit</Table.Th>
                {columnFields.map((column, colIndex) => (
                  <Table.Th style={{ width: "20%" }} key={column.id}>
                    {column.value}
                    {isEditMode && columnFields.length > 0 && (
                      <ActionIcon
                        disabled={columnFields.length === 0}
                        onClick={() => handleRemoveColumn(colIndex)}
                        variant="transparent"
                        aria-label="delete"
                      >
                        <img src="/images/svg/delete-icon.svg" alt="delete" />
                      </ActionIcon>
                    )}
                  </Table.Th>
                ))}
                <Table.Th style={{ width: "30%" }}>Deductible</Table.Th>
                <Table.Th style={{ width: "30%" }}>Currency</Table.Th>
                {plan === planTypes.MOTHER && <Table.Th>Actions</Table.Th>}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {rowFields.map((row, rowIndex) => (
                <Table.Tr key={row.id}>
                  {plan !== planTypes.MOTHER && (
                    <Table.Td>
                      <Controller
                        name={`rows.${rowIndex}.selected`}
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(event) =>
                              field.onChange(event.currentTarget.checked)
                            }
                            disabled={
                              !isEditMode && watchedRows[rowIndex]?.disabled
                            }
                          />
                        )}
                      />
                    </Table.Td>
                  )}

                  <Table.Td w={"20%"}>
                    <Controller
                      name={`rows.${rowIndex}.benefit`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          data={getAvailableOptions(rowIndex)}
                          value={field.value}
                          onChange={(value) => field.onChange(value)}
                          placeholder="Select Benefit"
                          searchable
                          disabled={
                            isEditMode || watchedRows[rowIndex]?.disabled
                          }
                        />
                      )}
                    />
                  </Table.Td>
                  {columnFields.map((column, colIndex) => (
                    <Table.Td key={column.id}>
                      <Controller
                        name={`rows.${rowIndex}.coverages.${colIndex}.coverage_amount`}
                        control={control}
                        render={({ field }) => (
                          <NumberInput
                            {...field}
                            description={
                              plan !== planTypes.MOTHER
                                ? `Max coverage ${watchedRows[rowIndex]?.coverages?.[colIndex]?.max_coverage}`
                                : ""
                            }
                            placeholder={`${column.value} Coverage `}
                            max={
                              watchedRows[rowIndex]?.coverages?.[colIndex]
                                ?.max_coverage || undefined
                            }
                            decimalScale={2}
                            allowNegative={false}
                            hideControls
                            disabled={
                              !isEditMode && watchedRows[rowIndex]?.disabled
                            }
                            style={{
                              marginBottom:
                                plan !== planTypes.MOTHER ? "18px" : "0",
                            }}
                          />
                        )}
                      />
                    </Table.Td>
                  ))}
                  <Table.Td>
                    <Controller
                      name={`rows.${rowIndex}.deductible_amount`}
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          placeholder="deductible"
                          disabled={
                            !isEditMode && watchedRows[rowIndex]?.disabled
                          }
                        />
                      )}
                    />
                  </Table.Td>

                  {/* currency */}
                  <Table.Td w={"10%"}>
                    <Controller
                      name={`rows.${rowIndex}.currency`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          data={["USD", "INR", "NPR"]}
                          placeholder="INR, USD, NPR etc"
                          disabled={
                            !isEditMode && watchedRows[rowIndex]?.disabled
                          }
                        />
                      )}
                    />
                  </Table.Td>

                  {isEditMode && (
                    <Table.Td>
                      <Button
                        variant="outline2"
                        type="button"
                        onClick={() => handleRemoveRowClick(row)}
                        disabled={
                          !isEditMode && watchedRows[rowIndex]?.disabled
                        }
                      >
                        Remove
                      </Button>
                    </Table.Td>
                  )}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>

          {/* Actions Buttons */}
          <Group justify="center" gap="xl" mt="xl">
            {plan === planTypes.MOTHER && !isEditMode && (
              <Button
                type="button"
                variant="outline"
                onClick={() =>
                  appendRow({
                    benefit: "",
                    coverages: columnFields.map(() => ({
                      cover_type: "",
                      coverage_amount: "",
                    })),
                    deductible_amount: "",
                    currency: "",
                  })
                }
              >
                Add Row
              </Button>
            )}

            {plan === planTypes.MOTHER && (
              <Button
                type="button"
                variant="outline"
                onClick={() =>
                  appendColumn({
                    value: `Option${columnFields.length + 1}`,
                  })
                }
              >
                Add Column
              </Button>
            )}

            <Button
              loading={isSubmitting}
              loaderProps={{ type: "dots" }}
              variant="gradient"
              type="submit"
            >
              Submit
            </Button>

            {!isEditMode && (
              <Link to={"edit"}>
                <Button variant="outline">Edit</Button>
              </Link>
            )}

            {plan === planTypes.MOTHER && !isEditMode && (
              <Button variant="outline2" type="button" onClick={() => reset()}>
                Reset
              </Button>
            )}
          </Group>
        </form>
      </Paper>

      <ConfirmModal
        opened={opened}
        onClose={close}
        onConfirm={handleConfirmRemoveRow}
        title="Benefit delete confirmation"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        Are you sure you want to delete this benefit
      </ConfirmModal>
    </>
  );
}

export default BenefitsTable;
