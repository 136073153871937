import TripStats from "../containers/TripStats/TripStats";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";
import { Paper, Grid, Divider } from "@mantine/core";
import ColumnChart from "../components/ColumnChart/ColumnChart";
import BarChart from "../components/BarChart/BarChart";
import PieChart from "../components/PieChart/PieChart";
import { USER_TYPES } from "../../../utils/userTypes";
import GroupBarChart from "../components/GroupBarChart/GroupBarChart";

const Dashboard = () => {
  const tripsInternational = `dashboard/trip-status/?cover_type=International`;
  const tripsDomestic = `dashboard/trip-status/?cover_type=Domestic`;
  const revenue = "dashboard/gateway-totals-amount/";
  const travellersEndpoint = "dashboard/policy-count-month-wise/";

  const { user_type } = useSelector((state) => state.auth);
  // const policiesCount = `dashboard/policy-count/`;
  // const vendorWisePolicy = `dashboard/vender-wise-policy`;
  const travellersByNationality = `dashboard/travellers-by-nationality`;

  const { data: tripsDataIN, isLoading: tripLoading } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER ? tripsInternational : null,
    fetcher
  );

  const { data: tripsDataDomestic, isLoading: tripDomesticLoading } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER ? tripsDomestic : null,
    fetcher
  );

  const { data: revenueData, isLoading: isRevenueLoading } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER ? revenue : null,
    fetcher
  );

  const { data: travellersData, isLoading: isTravellersLoading } = useSWR(
    travellersEndpoint,
    fetcher
  );

  const {
    data: travellersByNationalityStats,
    isLoading: travellersByNationalityLoading,
  } = useSWR(
    user_type[0] === USER_TYPES.SUPERUSER ? travellersByNationality : null,
    fetcher
  );

  // const { data: policiesStats, isLoading: policiesLoading } = useSWR(
  //   policiesCount,
  //   fetcher
  // );

  // const { data: vendorPoliciesStats, isLoading: vendorPoliciesLoading } =
  //   useSWR(vendorWisePolicy, fetcher);

  return (
    <>
      {user_type[0] === USER_TYPES.SUPERUSER ? (
        <>
          <Grid>
            <Grid.Col span={6}>
              <Paper p="sm">
                {tripLoading && tripDomesticLoading ? (
                  <div>Loading...</div>
                ) : (
                  <ColumnChart
                    internationalData={tripsDataIN}
                    domesticData={tripsDataDomestic}
                    xaxis={{
                      categories: [
                        "Active",
                        "Inactive",
                        "Matured",
                        "Pending",
                        "Expired",
                      ],
                      title: { text: "Status" },
                    }}
                    yaxis={{
                      title: { text: "Policies" },
                    }}
                  />
                )}
              </Paper>
            </Grid.Col>

            <Grid.Col span={6}>
              <Paper p="sm">
                {travellersByNationalityLoading ? (
                  <></>
                ) : (
                  <BarChart data={travellersByNationalityStats} />
                )}
              </Paper>
            </Grid.Col>
          </Grid>

          <Divider my="lg" />

          <Grid>
            {isRevenueLoading ? (
              <div>Loading...</div>
            ) : (
              <Grid.Col span={6}>
                {isTravellersLoading ? (
                  <></>
                ) : (
                  <Paper p="sm">
                    <PieChart data={revenueData} />
                  </Paper>
                )}
              </Grid.Col>
            )}

            <Grid.Col span={6}>
              <Paper p="sm">
                <GroupBarChart data={travellersData} />
              </Paper>
            </Grid.Col>
          </Grid>

          <Divider my="lg" />

          <TripStats />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
