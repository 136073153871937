import { useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../../../services/fetcher";
import { SimpleGrid } from "@mantine/core";
import CountStatsControls from "../../components/CountStatsControls/CountStatsControls";

const TripStats = () => {
  const [tripFilterQuery, setTripFilterQuery] = useState("?filter=today");
  const [policyFilterQuery, setPolicyFilterQuery] = useState("?filter=today");
  const [vendorFilterQuery, setVendorFilterQuery] = useState("?filter=today");

  const tripCount = `dashboard/trip-status${tripFilterQuery}`;
  const policiesCount = `dashboard/policy-count${policyFilterQuery}`;
  const vendorWisePolicy = `dashboard/vender-wise-policy${vendorFilterQuery}`;

  const { data: tripStats, isLoading: tripLoading } = useSWR(
    tripCount,
    fetcher
  );

  const { data: policiesStats, isLoading: policiesLoading } = useSWR(
    policiesCount,
    fetcher
  );

  const { data: vendorPoliciesStats, isLoading: vendorPoliciesLoading } =
    useSWR(vendorWisePolicy, fetcher);

  const tripStatsOptions = [
    { label: "Active", key: "active" },
    { label: "Expired", key: "expired" },
    { label: "InActive", key: "inactive" },
    { label: "Matured", key: "matured" },
    { label: "Pending", key: "pending" },
  ];

  const policyStatsOptions = [
    { label: "Travellers", key: "travellers_count" },
    { label: "Female", key: "female_policy_count" },
    { label: "Male", key: "male_policy_count" },
    { label: "Domestic", key: "domestic_policy_count" },
    { label: "International", key: "international_policy_count" },
  ];

  const vendorPolicyStatsOptions = [
    { label: "ASC360", key: "ASC360" },
    { label: "ICICI", key: "ICICI" },
    { label: "Null", key: "null" },
  ];

  const filterOptions = [
    { value: "today", label: "Today" },
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "year", label: "This Year" },
    { value: "custom", label: "Custom" },
  ];

  return (
    <SimpleGrid cols={{ base: 1, md: 1 }} spacing="md">
      <CountStatsControls
        data={tripStats}
        isLoading={tripLoading}
        onFilterChange={setTripFilterQuery}
        statsOptions={tripStatsOptions}
        filterOptions={filterOptions}
        statsTitle="Trips Counts"
        changeDayWise={true}
      />

      <CountStatsControls
        data={policiesStats}
        isLoading={policiesLoading}
        onFilterChange={setPolicyFilterQuery}
        statsOptions={policyStatsOptions}
        filterOptions={filterOptions}
        statsTitle="Policy Counts"
        changeDayWise={true}
      />

      <CountStatsControls
        data={vendorPoliciesStats}
        isLoading={vendorPoliciesLoading}
        onFilterChange={setVendorFilterQuery}
        statsOptions={vendorPolicyStatsOptions}
        filterOptions={filterOptions}
        statsTitle="Vendor Policy Counts"
        changeDayWise={false}
      />
    </SimpleGrid>
  );
};

export default TripStats;
