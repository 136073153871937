import axiosPrivate from "./axiosPrivate";

export const fetcher = async (
  url,
  method = "GET",
  data = null,
  headers = {},
  options = {}
) => {
  if (!headers || Object.keys(headers).length === 0) {
    headers = { "content-type": "application/json" };
  }

  const config = { ...options, method, headers, data };

  const response = await axiosPrivate(url, config);
  return response.data;
};

export const fileFetcher = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.blob();
};
