/**
 * Converts a date to YYYY-MM-DD format
 * @param {string|Date} date - The date to format
 * @returns {string} The formatted date string
 */

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString();
};
